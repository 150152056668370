import {_get} from "@api/services/httpService";

export const getClubStats = (startingDate, endingDate) => _get('/clubs/stats' + '?from=' + startingDate + '&to=' + endingDate);

export const getStatsGMV = (
  startingDate,
  endingDate,
  type = 'day',
  method = 'card',
  provider = 'stripe',
  status = 'succeeded'
) =>
  _get('/stats/payment-amount-by-date?fromDate=' + startingDate +
    '&toDate=' + endingDate +
    '&dateIntervalType=' + type +
    '&provider=' + provider +
    '&method=' + method +
    '&status=' + status
  );

export const getStatsPlaygrounds = (startingDate, endingDate, type = 'day') =>
  _get('/stats/club-playground-count-by-date?fromDate=' + startingDate +
    '&toDate=' + endingDate +
    '&dateIntervalType=' + type
  );

export const getStatsClubs = (startingDate, endingDate, type = 'day') =>
  _get('/stats/club-count-by-date?fromDate=' + startingDate +
    '&toDate=' + endingDate +
    '&dateIntervalType=' + type
  );

export const getClubBookingCount = (clubId = '', orderBy = 'year', orderByType = 'desc') =>
  _get('/stats/doinsport/nbrBooking?orderBy=' + orderBy +
    '&orderByType=' + orderByType +
    '&club_id=' + clubId
  );