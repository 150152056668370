<template>
  <div
      class="container-fluid stats-card-activity"
      :class="containerClass"
  >
    <b-card
        class="stats-card"
    >
      <b-row no-gutters class="row-style">
        <b-col cols="6">
          <d-body-nav-bar
              :tabs="[]"
              position="float-right"
              title="Club & Terrains"
          />
        </b-col>
        <b-col class="text-right p-2" cols="2">
          <div
            class="has-search"
            @click="displayUntilDateCalendar = !displayUntilDateCalendar"
          >
            <span class="input-date-picker calendar-size fa fa-calendar form-control-feedback"></span>
            <input
              type="text"
              readonly
              class="background-light-blue-inputs form-control pointer"
              :value="untilDateLabel | moment('DD-MM-YYYY')"
            >
          </div>
        </b-col>
        <b-col class="text-right p-2" cols="2">
          <div
            class="has-search"
            @click="displayUntilDateCalendarEnd = !displayUntilDateCalendarEnd"
          >
            <span class="input-date-picker calendar-size fa fa-calendar form-control-feedback"></span>
            <input
              type="text"
              readonly
              class="background-light-blue-inputs form-control pointer"
              :value="untilDateLabelEnd | moment('DD-MM-YYYY')"
            >
          </div>
        </b-col>
        <b-col class="text-right p-2" cols="2">
          <d-select-option
            :value="typeGraph"
            :options="['Day', 'Week', 'Month', 'Year']"
            :rules="null"
            @input="changeTypeGraph"></d-select-option>
        </b-col>
      </b-row>
      <div class="row">

        <div :class="$store.getters['layout/getInnerWidth'] < 1532 ? 'col-12' : 'col-6'">
          <chart
            v-if="loaded"
            legend="Évolution des Clubs"
            chart-color="#363565"
            :type="'Line'"
            :datasets="clubChartData"
          />
        </div>

        <div :class="$store.getters['layout/getInnerWidth'] < 1532 ? 'col-12' : 'col-6'">
          <chart
            v-if="loaded"
            legend="Évolution des Terrains"
            chart-color="#363565"
            :type="'Line'"
            :datasets="playgroundChartData"
          />
        </div>


      </div>
    </b-card>
    <d-calendar-modal
      identifier="chartUntilDate"
      :show="displayUntilDateCalendar"
      :filterAfterDate="new Date"
      @on:calendar-change="onUntilDateChange"
    />
    <d-calendar-modal
      identifier="chartUntilDateEnd"
      :show="displayUntilDateCalendarEnd"
      :filterAfterDate="new Date"
      @on:calendar-change="onUntilDateEndChange"
    />
  </div>
</template>
<script>
import {getClubStats, getStatsClubs, getStatsGMV, getStatsPlaygrounds} from "@api/services/stats/stats.api";

export default {
  data: () => ({
    typeGraph: 'Day',
    untilDateLabel: null,
    untilDateLabelEnd: null,
    displayUntilDateCalendar: false,
    displayUntilDateCalendarEnd: false,
    loaded: false,
    bookingsChartData: [],
    clubChartData: {},
    playgroundChartData: {},
    paymentsChartData: [],
    analytics: null,
  }),
  props: {
    containerClass: {
      type: String,
      default: '',
    }
  },
  watch : {
    typeGraph: function() {
      this.loadGmv();
    },
    untilDateLabel: function() {
      this.loadGmv();
    },
    untilDateLabelEnd: function() {
      this.loadGmv();
    }
  },
  components: {
    Chart: () => import('./Chart'),
    Analytics: () => import('./Analytics')
  },
  methods: {
    changeTypeGraph(e) {
      this.typeGraph = e;
    },
    loadGmv() {
      this.loaded = false;
      getStatsClubs(
        this.untilDateLabel.format('YYYY-MM-DD'),
        this.untilDateLabelEnd.format('YYYY-MM-DD'),
        this.typeGraph.toLowerCase()
      ).then(resp => {
        const series = [];
        resp.data.x.forEach(item => {
          if (this.typeGraph === 'Day') {
            series.push(this.$moment(item).format('DD/MM/YYYY'));
          } else {
            series.push(this.$moment(item + '/01').format('MMM Y'));
          }
        });
        this.clubChartData = [series, resp.data.y];

        getStatsPlaygrounds(
          this.untilDateLabel.format('YYYY-MM-DD'),
          this.untilDateLabelEnd.format('YYYY-MM-DD'),
          this.typeGraph.toLowerCase()
        ).then(resp => {
          const series = [];
          resp.data.x.forEach(item => {
            if (this.typeGraph === 'Day') {
              series.push(this.$moment(item).format('DD/MM/YYYY'));
            } else {
              series.push(this.$moment(item + '/01').format('MMM Y'));
            }
          });
          this.playgroundChartData = [series, resp.data.y];
          this.loaded = true;
        });
      });
    },
    onUntilDateChange(selectedDate) {
      this.untilDateLabel = this.$moment(selectedDate);
      this.loadChartData(this.$moment(selectedDate).format('YYYY-MM-DD'));
    },
    onUntilDateEndChange(selectedDate) {
      this.untilDateLabelEnd = this.$moment(selectedDate);
      this.loadChartData(this.$moment(selectedDate).format('YYYY-MM-DD'));
    },
    loadChartData(dateFrom) {
      this.loaded = false;

      getClubStats(dateFrom, this.$moment().format('YYYY-MM-DD'))
          .then(
              (response) => {
                this.bookingsChartData = response.data.charts.bookings;
                this.paymentsChartData = response.data.charts.payments;
                this.analytics = {
                  ca: {
                    value: response.data.stats.payments.current,
                    evolution: response.data.stats.payments.current - response.data.stats.payments.previous,
                  },
                  bookings: {
                    value: response.data.stats.bookings.current,
                    evolution: response.data.stats.bookings.current - response.data.stats.bookings.previous,
                  }
                };
                this.loaded = true;
              }
          )
      ;
    }
  },
  created() {
    this.untilDateLabel = this.$moment();
    this.untilDateLabel.subtract(7, 'days');
    this.untilDateLabelEnd = this.$moment();

    this.loadGmv();


    this.loadChartData('');
  }
}
</script>
<style lang="scss" scoped>
.stats-card .card-body {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 463px;
  padding: 1.25rem;
}


.stats-card-activity .card {
  box-shadow: 0px 10px 16px #E2E1E6;
  border-radius: 8px;
}

.btn-light:hover {
  color: #435179;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.center-analytics {
  display: flex;
  align-items: center;
}

.calendar-size {
  font-size: 20px;
}

.calendar-size.fa-calendar {
  color: #0c5460
}

.input-date-picker .form-control:disabled, .form-control[readonly] {
  background-color: #EAF2F7;
  opacity: 1;
}

/deep/ .has-search .form-control-feedback {
  z-index: 0;
}

</style>
